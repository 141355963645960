<template>
  <div v-if="hasResource('review_management')">
    <div class="assets-approval">
      <div class="search-form">
        <el-button
          class="black-btn"
          @click="handleApproval"
          v-if="hasResource('review_agree')"
          >批量审批</el-button
        >
        <el-button
          class="white-btn"
          @click="handleReject"
          v-if="hasResource('review_reject')"
          >批量驳回</el-button
        >
        <div class="dis-fix">
          <el-form :inline="true" ref="form" :model="form" :rules="rules">
            <el-form-item label="审批单号" prop="review_no">
              <el-input
                v-model="form.review_no"
                placeholder="输入单号检索"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="外部ID" prop="asset_uid">
              <el-input
                v-model="form.asset_uid"
                placeholder="输入ID"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="资产名称" prop="asset_name">
              <el-input
                v-model="form.asset_name"
                placeholder="输入名称"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="申请时间">
              <el-date-picker
                :picker-options="datePick"
                v-model="form.applyDate"
                style="width: 168px"
                format="yyyy.MM.dd"
                type="daterange"
                range-separator="-"
                :maxTime="today"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="请选择"
                end-placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="审批时间">
              <el-date-picker
                :picker-options="datePick"
                v-model="form.approveDate"
                style="width: 168px"
                format="yyyy.MM.dd"
                type="daterange"
                range-separator="-"
                :maxTime="today"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="请选择"
                end-placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="发布时间">
              <el-date-picker
                v-model="form.publishDate"
                style="width: 168px"
                format="yyyy.MM.dd"
                type="daterange"
                range-separator="-"
                value-format="yyyy-MM-dd HH:mm:ss"
                start-placeholder="请选择"
                end-placeholder="请选择"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="申请人" prop="creator">
              <el-input
                v-model="form.creator"
                placeholder="输入申请人检索"
                clearable
                @keyup.enter.native="addByEnterKey"
              ></el-input>
            </el-form-item>
            <el-form-item label="审批状态">
              <el-select v-model="form.status">
                <el-option
                  v-for="item in options.status"
                  :key="item.name"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="text-btn">
            <span class="serach-button" @click="search">查询</span>
            <span class="clear-button" @click="cancel">清空</span>
          </div>
        </div>
      </div>
      <el-table
        ref="multipleTable"
        :data="assetsData"
        class="data-table"
        style="width: 100%"
        header-cell-class-name="table-head"
        cell-class-name="table-cell"
        v-loading="isTableLoading"
        tooltip-effect="dark"
        @selection-change="handleSelectionChange"
        :default-sort="{ prop: 'id', order: 'descending' }"
        @sort-change="sortChange"
      >
        <div v-for="col in tableColumns" :key="col.prop">
          <el-table-column
            v-if="col.label === '复选框'"
            type="selection"
            header-align="left"
            align="left"
            :fixed="col.fixed"
            show-overflow-tooltip
            :width="col.width"
          ></el-table-column>
          <el-table-column
            v-else
            :key="col.prop"
            :prop="col.prop"
            :label="col.label"
            :type="col.type"
            :width="col.width"
            :align="col.align"
            :fixed="col.fixed"
            :sortable="
              col.prop == 'review_no' ||
              col.prop == 'asset_uid' ||
              col.prop == 'asset_name' ||
              col.prop == 'created_at' ||
              col.prop == 'approved_at' ||
              col.prop == 'asset_publish_time'
                ? 'custom'
                : false
            "
            draggable="true"
          >
            <template slot-scope="{ row }">
              <div v-if="col.prop == 'operation'" class="icon-btn">
                <el-button
                  size="mini"
                  type="text"
                  class="preview-color"
                  @click="goToApprovalAssetInfo(row)"
                  v-if="hasResource('review_info')"
                >
                  <i class="iconfont">&#xe662;</i>查看
                </el-button>
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="approvalAsset(row)"
                  v-if="row.status == 0 && hasResource('review_agree')"
                >
                  <i class="iconfont">&#xe65b;</i>审批
                </el-button>
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="rejectAsset(row)"
                  v-if="row.status == 0 && hasResource('review_reject')"
                >
                  <i class="iconfont">&#xe664;</i>驳回
                </el-button>
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="resubmitAssets(row)"
                  v-if="row.status == 2 && hasResource('review_reset')"
                >
                  <i class="iconfont">&#xe665;</i>重新提交
                </el-button>
                <el-button
                  class="preview-color"
                  size="mini"
                  type="text"
                  @click="closeAssets(row)"
                  v-if="row.status == 2 && hasResource('review_close')"
                >
                  <i class="iconfont">&#xe678;</i>关闭
                </el-button>
              </div>
              <div v-else-if="col.prop == 'status'">
                <span v-if="row.status == 0">待审批</span>
                <span v-if="row.status == 1">已审批</span>
                <span v-if="row.status == 2">已驳回</span>
                <span v-if="row.status == 3">已关闭</span>
              </div>
              <div v-else-if="col.prop == 'created_at'">
                {{ dateFilter(row.created_at)}}
              </div>
              <div v-else-if="col.prop == 'approved_at'">
                {{ dateFilter(row.approved_at) }}
              </div>
              <div v-else-if="col.prop == 'asset_publish_time'">
                {{
                  !row.asset_instant_publish
                    ? dateFilter(row.asset_publish_time)
                    : "立即发布"
                }}
              </div>
              <div v-else>{{ row[col.prop] }}</div>
            </template>
          </el-table-column>
        </div>
      </el-table>
      <div class="ta-r mg-t-18">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageNumber"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          v-if="total != 0"
        ></el-pagination>
      </div>

      <!-- 审批提示弹框 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleApproval"
        width="458px"
        class="add-dialog dialogTip"
      >
        <div class="dialog-content">
          <div class="dialog-title">审批资产</div>
          <div class="dialog-dec">
            您将审批资产
            <span>{{ assetsName }}</span>
          </div>
          <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="50px">
              <el-form-item label="备注" prop="agreeComment">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="不超过30个字"
                  v-model="form.agreeComment"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button @click="approvalSubmit" class="confirm-button"
            >确认审批</el-button
          >
          <el-button
            @click="
              dialogVisibleApproval = false;
              form.agreeComment = null;
            "
            class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 驳回资产 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleReject"
        width="458px"
        class="add-dialog dialogTip"
      >
        <div class="dialog-content">
          <div class="dialog-title">驳回资产</div>
          <div class="dialog-dec">
            您将驳回资产
            <span>{{ assetsName }}</span>
          </div>
          <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="50px">
              <el-form-item label="备注" prop="rejectComment">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="不超过30个字"
                  v-model="form.rejectComment"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button @click="rejectSubmit" class="confirm-button"
            >确认驳回</el-button
          >
          <el-button
            @click="
              dialogVisibleReject = false;
              form.rejectComment = null;
            "
            class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 批量审批提示弹框 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogApproval"
        width="458px"
        class="add-dialog dialogTip"
      >
        <div class="dialog-content">
          <div class="dialog-title">您将批量审批所选择的资产</div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button class="confirm-button" @click="approvalSubmit('批量')" :loading="approvalLoading"
            >确认</el-button
          >
          <el-button @click="dialogApproval = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 批量驳回弹框 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogReject"
        width="458px"
        class="add-dialog dialogTip"
      >
        <div class="dialog-content">
          <div class="dialog-title">您将批量驳回所选择的资产</div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button class="confirm-button" @click="rejectSubmit('批量')"  :loading="rejectLoading"
            >确认</el-button
          >
          <el-button @click="dialogReject = false" class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>

      <!-- 关闭提示弹框 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="dialogVisibleClose"
        width="458px"
        class="add-dialog dialogTip"
      >
        <div class="dialog-content">
          <div class="dialog-title">关闭资产</div>
          <div class="dialog-dec">
            您将关闭资产
            <span>{{ assetsName }}</span>
          </div>
          <div>
            <el-form ref="form" :model="form" :rules="rules" label-width="50px">
              <el-form-item label="备注" prop="closeComment">
                <el-input
                  type="textarea"
                  :rows="2"
                  placeholder="不超过30个字"
                  v-model="form.closeComment"
                ></el-input>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button @click="closeSubmit" class="confirm-button"
            >确认关闭</el-button
          >
          <el-button
            @click="
              dialogVisibleClose = false;
              form.closeComment = null;
            "
            class="cancel-button"
            >取 消</el-button
          >
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Api from "../../../api/asset";
import { formatDate } from 'ecoplants-lib';
export default {
  data() {
    var disableEndDate = (time) => {
      let _now = Date.now();
      //大于当前的禁止
      return time.getTime() > _now;
    };
    return {
      header: {
        url: "assetsApprove",
      },
      today: new Date(),
      tableColumns: [
        {
          prop: "select",
          label: "复选框",
          width: 100,
          align: "left",
          fixed: "left",
          type: "select",
        },
        { prop: "review_no", label: "审批单号", width: 180, align: "left" },
        { prop: "asset_uid", label: "外部ID", width: 160, align: "left" },
        { prop: "asset_name", label: "资产名称", width: 180, align: "left" },
        { prop: "created_at", label: "申请时间", width: 190, align: "left" },
        { prop: "approved_at", label: "审批时间", width: 190, align: "left" },
        {
          prop: "asset_publish_time",
          label: "发布时间",
          width: 190,
          align: "left",
        },
        // { prop: "type", label: "申请类型", width: 120, align: "left" },
        { prop: "creator", label: "申请人", width: 180, align: "left" },
        { prop: "status", label: "审批状态", width: 120, align: "left" },
        {
          prop: "operation",
          label: "操作",
          align: "left",
          width: 320,
          fixed: "right",
        },
      ],
      options: {
        status: [
          { name: "全部", id: "" },
          { name: "待审批", id: 0 },
          { name: "已审批", id: 1 },
          { name: "已驳回", id: 2 },
          { name: "已关闭", id: 3 },
        ],
      },
      form: {
        asset_uid: "",
        assetId: "",
        assetsName: "",
        status: "",
        applyDate: "",
        approveDate: "",
        publishDate: "",
        rejectComment: "",
        agreeComment: "",
        closeComment: "",
      },
      assetsName: "",
      selectAssets: [],
      // 上传数据
      action: "",
      // 表格数据
      assetsData: [],
      pagesize: 10,
      pageNumber: 1,
      total: 0,
      order_by: "updated_at",
      sort: "DESC",
      isTableLoading: false,
      value: [],
      // 审批弹框
      dialogVisibleApproval: false,
      //批量审批
      dialogApproval: false,
      // 驳回
      dialogVisibleReject: false,
      //批量驳回
      dialogReject: false,
      // 关闭弹框
      dialogVisibleClose: false,
      ids: "",
      rules: {
        rejectComment: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        agreeComment: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        closeComment: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        review_no: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        asset_name: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        asset_uid: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
        creator: [
          {
            min: 1,
            max: 30,
            message: "长度在 1 到 30 个字符",
            trigger: "blur",
          },
        ],
      },
      datePick: {
        disabledDate(time) {
          return disableEndDate(time);
        },
      },
      review_no: "",
      asset_name: "",
      status: "",
      creator: "",
      applyDate: "",
      approveDate: "",
      publishDate: "",
      assetNameId: "",
      recordDatd: {},
      approvalLoading:false,
      rejectLoading:false
    };
  },
  methods: {
    async init() {
      if (this.getLocalCurrentPage("needUpdate")) {
        await this.updateFilterParams();
        await this.getApprovalList();
        this.recordCurrentPage("needUpdate", false);
      } else {
        await this.getApprovalList();
      }
    },

    updateFilterParams() {
      this.recordDatd = this.getLocalCurrentPage("approvalList0415");
      let params = this.recordDatd;
      if (params) {
        this.parameters = true;
        this.pagesize = params ? params.limit : this.pagesize;
        this.pageNumber = params ? params.page : this.pageNumber;
        this.form.review_no = params ? params.review_no : this.review_no;
        this.form.asset_uid = params ? params.asset_uid : this.form.asset_uid;
        this.form.assetsName = params
          ? params.asset_name
          : this.form.assetsName;
        this.form.applyDate = params
          ? params.asset_applyData
          : this.form.applyDate;
        this.form.approveDate = params
          ? params.asset_approveDate
          : this.form.approveDate;
        this.form.publishDate = params
          ? params.asset_publishDate
          : this.form.publishDate;
        this.form.rejectComment = params
          ? params.creator
          : this.form.rejectComment;
        this.form.status = params ? params.status : this.form.status;
        this.getApprovalList();
      }
    },
    getApprovalList() {
      let data = {
        limit: this.pagesize,
        order_by: this.order_by,
        page: this.pageNumber,
        sort: this.sort,
        filter: {
          review_no: this.review_no || this.recordDatd.review_no,
          asset_name:
            this.assetNameId ||
            this.form.asset_name ||
            this.recordDatd.asset_name,
          asset_uid: this.form.asset_uid || this.recordDatd.asset_uid,
          creator: this.creator || this.recordDatd.creator,
          created_at:
            {
              from: this.applyDate.length > 1 ? new Date(this.applyDate[0]).getTime()/1000 : "",
              to:
                this.applyDate.length > 1
                  ? new Date(this.applyDate[1].split(" ")[0] + " 23:59:59").getTime()/1000
                  : "",
            } || this.recordDatd.asset_applyData,
          approved_at:
            {
              from: this.approveDate.length > 1 ?  new Date(this.approveDate[0]).getTime()/1000 : "",
              to:
                this.approveDate.length > 1
                  ? new Date(this.approveDate[1].split(" ")[0] + " 23:59:59").getTime()/1000
                  : "",
            } || this.recordDatd.asset_approveDate,
          publish_at:
            {
              from: this.publishDate.length > 1 ? new Date(this.publishDate[0]).getTime()/1000 : "",
              to:
                this.publishDate.length > 1
                  ? new Date(this.publishDate[1].split(" ")[0] + " 23:59:59").getTime()/1000
                  : "",
            } || this.recordDatd.review_no,
        },
        market: "US",
        search: "",
      };
      if (this.status === 0 || this.status) {
        data.filter.status = this.status;
        }
      this.isTableLoading = true;
      Api.reviewList(data).then((res) => {
        if (res.data.code === 10200) {
          this.assetsData = res.data.data.items;
          this.total = res.data.data.total;
          setTimeout(() => {
            this.$nextTick(() => {
              this.$refs.multipleTable.doLayout();
            });
          }, 300);
        } else {
          this.$$error(res.data.message);
        }
        this.isTableLoading = false;
      });
    },
    addByEnterKey(e) {
      if (e.keyCode == 13) {
        this.search();
      }
    },
    dateFilter(val) {
        return val ? formatDate(val * 1000, 'yyyy-MM-dd hh:mm:ss') : '--';
    },
    search() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.pageNumber = 1;
          this.review_no = this.form.review_no;
          this.status = this.form.status;
          this.creator = this.form.creator;
          this.applyDate = this.form.applyDate;
          this.approveDate = this.form.approveDate || [];
          this.publishDate = this.form.publishDate || [];
          if (this.form.asset_name === undefined) {
            this.init();
          } else {
            var strArr = this.form.asset_name.split(""); //将字符串进行拆分
            var reg = /^[A-Z]+$/; //正则A-Z
            let newStr = "";
            for (let i = 0; i < this.form.asset_name.length; i++) {
              if (reg.test(strArr[i]) && i != 0 && strArr[i - 1] !== " ") {
                strArr[i] = " " + strArr[i];
              }
              newStr += strArr[i];
            }
            this.assetNameId = newStr;
            this.pageNumber = 1;
            this.init();
          }
        }
      });
    },
    cancel() {
      this.form = {
        status: "",
        review_no: "",
        assetName: "",
        assetId: "",
        assetsName: "",
        applyDate: "",
        rejectComment: "",
        agreeComment: "",
        closeComment: "",
        asset_name: "",
      };
    },
    // 批量审批
    handleApproval() {
      if (this.selectAssets.length == 0) {
        this.$$error("请先勾选数据~");
        return;
      } else if (
        this.selectAssets.length !=
        this.selectAssets.filter((ele) => {
          return ele.status == 0;
        }).length
      ) {
        let message = "有不处于待审批状态的资产，不可批量处理。";
        this.$$error(message);
        return;
      }
      this.dialogApproval = true;
      this.form.agreeComment = null;
    },
    approvalSubmit(type) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.approvalLoading=true;
          let arr = [];
          this.selectAssets.forEach((element) => {
            arr.push(element.id);
          });
          let data = {
            market: "US",
            id: arr,
            comment: this.form.agreeComment,
          };
          Api.agreeAssets(data).then((resp) => {
            if (resp.data.code == 10200) {
              if (this.dialogApproval) {
                this.$$success("批量处理成功!");
              }
              this.selectAssets = [];
              this.form.agreeComment = null;
              this.dialogApproval = false;
              this.dialogVisibleApproval = false;
              this.init();
            } else {
              this.$$error(res.data.message);
            }
            this.approvalLoading=false;
          });
        } else {
          return;
        }
      });
    },
    // 批量驳回
    handleReject() {
      if (this.selectAssets.length == 0) {
        this.$$error("请先勾选数据~");
        return;
      } else if (
        this.selectAssets.length !=
        this.selectAssets.filter((ele) => {
          return ele.status == 0;
        }).length
      ) {
        let message = "有不处于待审批状态的资产，不可批量处理。";
        this.$$error(message);
        return;
      }
      this.dialogReject = true;
      this.form.rejectComment = null;
    },
    rejectSubmit(row) {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.rejectLoading=true;
          let arr = [];
          this.selectAssets.forEach((element) => {
            arr.push(element.id);
          });
          let data = {
            market: "US",
            id: arr,
            comment: this.form.rejectComment,
          };
          Api.rejectAssets(data).then((resp) => {
            if (resp.data.code == 10200) {
              if (this.dialogReject) {
                this.$$success("批量处理成功!");
              }
              this.selectAssets = [];
              this.form.rejectComment = null;
              this.dialogReject = false;
              this.dialogVisibleReject = false;
              this.init();
            } else {
              this.$$error(res.data.message);
            }
            this.rejectLoading=false;
          });
        }
      });
    },
    handleSelectionChange(data) {
      this.selectAssets = data;
    },
    handleSizeChange(val) {
      this.parameters = false;
      this.pagesize = val;
      this.init();
    },
    handleCurrentChange(val) {
      this.parameters = false;
      this.pageNumber = val;
      this.init();
    },
    // 审批弹框
    approvalAsset(row) {
      if (this.selectAssets.length > 1) {
        return;
      } else {
        this.selectAssets = [];
      }
      this.dialogVisibleApproval = true;
      this.assetsName = row.asset_name;
      this.selectAssets.push(row);
    },
    // 重新提交
    resubmitAssets(row) {
      this.assetsName = row.asset_name;
      this.ids = row.asset_id;
      this.$router.push({
        path: "/asset/publish",
        query: {
          id: row.id,
          type: "approval",
        },
      });
    },
    //驳回弹框
    rejectAsset(row) {
      if (this.selectAssets.length > 1) {
        return;
      } else {
        this.selectAssets = [];
      }
      this.dialogVisibleReject = true;
      this.assetsName = row.asset_name;
      this.selectAssets.push(row);
    },
    //关闭弹框
    closeAssets(row) {
      this.dialogVisibleClose = true;
      this.assetsName = row.asset_name;
      this.selectAssets.push(row);
    },
    closeSubmit() {
      if (this.selectAssets.length == 0 || this.selectAssets.length > 1) {
        return;
      }
      this.$refs["form"].validate((valid) => {
        if (valid) {
          let arr = [];
          this.selectAssets.forEach((element) => {
            arr.push(element.id);
          });
          let data = {
            market: "US",
            id: arr,
            comment: this.form.closeComment,
          };
          Api.closeAsset(data).then((resp) => {
            if (resp.data.code == 10200) {
              this.dialogVisibleClose = false;
              this.init();
              this.form.closeComment = null;
            } else {
              this.$$error(res.data.message);
            }
          });
        }
      });
    },
    // 查看
    goToApprovalAssetInfo(row) {
      this.$router.push({
        path: "/approval-details",
        query: {
          id: row.id,
        },
      });
      const params = {
        limit: this.pagesize,
        page: this.pageNumber,
        review_no: this.review_no,
        asset_uid: this.form.asset_uid,
        asset_name: this.form.assetsName,
        asset_applyData: this.form.applyDate,
        asset_approveDate: this.form.approveDate,
        asset_publishDate: this.form.publishDate,
        creator: this.form.rejectComment,
        status: this.form.status,
      };
      this.recordCurrentPage("approvalList0415", params);
    },
    sortChange(column) {
      if (column.prop == "asset_publish_time") {
        column.prop = "published_at";
      }
      if (column.prop == "asset_name") {
        column.prop = "name";
      }
      this.order_by = column.prop;
      this.sort = column.order == "descending" ? "DESC" : "ASC";
      this.init();
    },
  },
  getStatus(status) {
    switch (status) {
      case 0:
        return "待审批";
      case 1:
        return "已审批";
      case 2:
        return "已驳回";
      case 3:
        return "已关闭";
    }
  },
  mounted() {
    this.msgBus.$on("approvalList", () => {
      this.recordCurrentPage("needUpdate", true);
    });
    this.init();
  },
  activated() {
    if (this.$route.meta.keepAlive) {
      this.init();
    }
  },
};
</script>
<style lang="less">
@import "index.less";
</style>



